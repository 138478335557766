import { BaseElement } from '../../01-base/BaseElement';
import Glide from '@glidejs/glide';

class SliderComponent extends BaseElement {
    properties() {
        return {
            options: {
                type: 'carousel',
                autoplay: '0',
                perView: 1,
                navigation: true,
            },
            selector: '',
            sliderAutoplay: '0',
        };
    }

    hooks() {
        return {
            connected: () => {
                this.options.autoplay = this.sliderAutoplay;
                new Glide(this.selector, this.options).mount();
            },
        };
    }
}

customElements.define('slider-component', SliderComponent);
